// Body
$body-bg: #ffffff;

// Typography
$font-family-oswald: 'Oswald', sans-serif;
$font-family-open-sans: 'Open Sans', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$font-family-base: 'Open Sans';
$fa-font-path: "../webfonts";

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$tkc-green: #006D77;
$tkc-pink: #ED218A;
$tkc-grey: #F9F9FA;
$tkc-grey2: #828282;

$body-color: #3C3C3B;
$headings-color: $tkc-green;
$social-color: #ADADAE;
$btn-tkc-pink: $tkc-pink;

$facebook-blue: #4267B2;

