// Fonts
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Icons
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';

body{
    font-family: $font-family-open-sans;
    color: $body-color;
}
body {
    touch-action: pan-x pan-y;
}
h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-oswald;
    color: $tkc-green;
}

.expanderHintOverlay {
    cursor: pointer;
    opacity: 0;
}

.expanderHintOverlay:hover {
    opacity: 100;
}

.tkc-expander-dismiss {
    position: absolute;
    top: 10px;
    right: 16px;
    cursor: pointer;
    color: #fff;
    z-index:9999999;
}

.tkc-scrollerThumbnail {
    margin-right: 48px;
}

.tkc-scrollerThumbnail:hover {
    opacity: 0.7;
}


.txt-tkc {
    color: $tkc-green;
}

.txt-tkc::placeholder {
    color: $tkc-green;
}

.txt-tkc::-webkit-input-placeholder {
    color: $tkc-green;
}

.txt-tkc::-moz-placeholder {
    color: $tkc-green;
}

.txt-tkc:-ms-input-placeholder {
    color: $tkc-green;
}

.txt-tkc::-ms-input-placeholder {
    color: $tkc-green;
}

.txt-tkc-pink {
    color: $tkc-pink;
}

.liked {
  color: #ED218A !important;
}

.like-button-for-categories {
  cursor: pointer;
}

.info-button-for-categories {
    cursor: pointer;
}

.btn-tkc-info-overlay {
  color: #ffffff;
  text-shadow: 4px 3px #000000;
}

.btn-tkc-info-overlay:hover {
  color: #ED218A;
}

.btn-tkc-like-overlay {
  top: 50%;
  color: #ffffff;
  text-shadow: 3px 4px #000000;
}

.btn-tkc-like-overlay:hover {
  color: #ED218A;
}

.category-contents-overlay-popup {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  display: none;
  color: #000000 !important;
}

.btn-tkc-pink {
    color: #fff;
    background-color: $tkc-pink;
    border-color: $tkc-pink;
}

.btn-facebook-blue {
    color: #fff;
    background-color: $facebook-blue;
    border-color: $facebook-blue;
}

.social-proof {
    color: $social-color;
    font-family: $font-family-oswald;
    font-size: 32pt;
}
.social-text {
    color: $social-color;
    font-family: $font-family-oswald;
    font-size: 16pt;
}

.action-link {
    color: $tkc-pink;
    font-family: $font-family-oswald;

    @extend %fa-icon;
    @extend .fas;

    &:after {
        font-family: "Font Awesome 5 Free"; font-weight: 900;
        content: fa-content(\00a0$fa-var-chevron-right);
    }

    border: 0px;
    padding-left: 0;
}


.action-link-reverse {
    color: $tkc-pink;
    font-family: $font-family-oswald;

    @extend %fa-icon;
    @extend .fas;

    &:before {
        font-family: "Font Awesome 5 Free"; font-weight: 900;
        content: fa-content($fa-var-chevron-left \00a0);
    }

    border: 0px;
    padding-right: 0;
}

.tkc-grey-bg {
    background-color: $tkc-grey;
}

.tkc-banner {
    background-size: cover;
    background-position-y: center;
    background-position-x: center;
}

.cellphone-surround-responsive-text-leftside {
  text-align: right;
}

.cellphone-surround-responsive-text-rightside {
  text-align: left;
}

.tkc-refe-logo {
  max-height: 100px;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.3;
}

.starbat {
    height: 10vh;
}

.video-length{
    position : absolute;
    top:170px;
    right:25px;
    width: 70px;
    height: 30px;
    line-height: 30px;
    z-index:99;
    color: white;
    background: black;
    filter: opacity(0.6);
    text-align: center;
}

.video-length-2{
    position : absolute;
    bottom:20px;
    right:30px;
    width: 70px;
    height: 30px;
    line-height: 30px;
    z-index:99;
    color: white;
    background: black;
    filter: opacity(0.6);
    text-align: center;
}

.video-length-expander{
    position : relative;
    bottom:70px;
    left:130px;
    width: 70px;
    height: 20px;
    line-height: 20px;
    z-index:99;
    color: white;
    background: black;
    filter: opacity(0.6);
    text-align: center;
}

.video-img-container {
    position: relative;
}

.video-img-image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.video-img-middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.video-img-container:hover .video-img-image {
    opacity: 0.3;
}

.video-img-container:hover .video-img-middle {
    opacity: 1;
}


.overlay-items{
    position : absolute;
    top:0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    margin:0;
    right:0px;
    z-index:100;
    background-color: rgba(0,0,0,.15);
}
.expander-button{
    width: 100%;
    height: 100%;
    min-height: 100%;
    cursor: pointer;
    z-index:101;
}
.play-button{
    width: 100%;
    min-height: 70%;
    max-height: 100%;
    z-index:102;
}
.no-expander{
    height: 100%;
}
.play-button:hover {
    background-color: rgba(0,0,0,.25);
}
.expander-button:hover {
    background-color: rgba(0,0,0,.25);
}

.playButton {
    //width: 5vw;
    //max-width: 64px;
}

.expandButtonOverlay {

}

.expandButton {
    width: 64px;
}

.expandButtonOverlay:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.courseArrowLeft {
    position: absolute;
    top: 30%;
    left: 0px;
}

.courseArrowRight {
    position: absolute;
    top: 30%;
    right: 0px;
}

.fixedSizeCourseVideoImage {
    height: 21vh;
    border: 1px solid #CCCCCC;
}

.fixedSizeResponsiveVideoImage {
    width: 17vw;
    height: auto;
    border: 1px solid #CCCCCC;
}
.carousel-border{
    border: 1px solid #CCCCCC;
    max-width:338px;
    max-height:193px;
    object-fit: cover;
    width: 100%;
    height: 193px;
}
.horizontalScroll {
    white-space: nowrap !important;
    -webkit-overflow-scrolling: touch !important;
};

.responsiveVideoTitle {
    font-size: 1rem;
}

.searchResultsThumbnailImage {
    height: 15vh !important;
}

.nav.navbar-nav .nav-link {
    color: $tkc-green;
}

.nav .nav-link {
    color: $tkc-green;
}

.card-img-overlay-top-left {
    position: absolute;
    top: 0;
    padding: 0.5rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

/* Desktop - all the way to 4k screens. We may wanna customize for 4k 8k later?*/

@media all and (min-width: 1024px) and (max-width: 4000px) {
    .responsiveVideoTitle {
      font-size: 1rem;
      max-width: 17vw;
    }

    .fixedSizeResponsiveVideoImage {
      width: 17vw;
      height: auto;
    }

    .tkc-scrollerThumbnail {
      margin-right: 48px;
    }

    .searchResultsThumbnailImage {
      height: 25vh !important;
    }

    .starbat {
      height: 10vh;
    }
  }


/* Tablets */

@media all and (min-width: 768px) and (max-width: 1024px) {
    .fixedSizeResponsiveVideoImage {
      width: 25vw;
      height: auto;
    }

    .responsiveVideoTitle {
      max-width: 20vw;
      font-size: 0.75rem;
    }

    .tkc-scrollerThumbnail {
      margin-right: 48px;
    }

    .responsiveCategoryTitle {
      font-size: 16px;
    }

    .starbat {
      height: 7vh;
    }

    .expandButton {
      width: 48px;
    }
  }

/* Phones */

@media all and (min-width: 480px) and (max-width: 768px) {
    .cellphone-surround-responsive-text-leftside {
      text-align: center;
    }
    .social-proof {
        font-size: 8.5vw;
    }

    .cellphone-surround-responsive-text-rightside {
      text-align: center;
    }

    .tkc-scrollerThumbnail {
      margin-right: 32px;
    }

    .fixedSizeResponsiveVideoImage {
      width: 25vw;
      height: auto;
    }

    .responsiveVideoTitle {
      max-width: 25vw;
      font-size: 0.75rem;
    }

    .responsiveCategoryTitle {
      font-size: 16px;
    }

    .starbat {
      height: 5vh;
    }

    .expandButton {
      width: 32px;
    }

    .playButton {
      width: 30%;
    }

  }

/* Landscape phone */
@media only screen and (min-device-width: 666px)
    and (max-device-width: 1023px)
    and (min-device-height: 300px)
    and (max-device-height: 767px)
    and (orientation: landscape) {
    .responsiveVideoTitle {
      max-width: 20vw;
      font-size: 0.65rem;
    }

    .tkc-scrollerThumbnail {
      margin-right: 32px;
    }

    .cellphone-surround-responsive-text-leftside {
      text-align: center;
    }

    .cellphone-surround-responsive-text-rightside {
      text-align: center;
    }

    .fixedSizeResponsiveVideoImage {
      width: 20vw;
      height: auto;
    }

    .responsiveCategoryTitle {
      font-size: 16px;
    }

    .starbat {
      height: 5vh;
    }

    .expandButton {
      width: 32px;
    }

    .playButton {
      width: 40%;
      max-width: 64px;
    }

    .playButtonOverlay {
      position: absolute;
      z-index: 999;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 20%;
      width: 100%;
    }

  }

/* Watches etc. */
@media all and (max-width: 480px) {
    .responsiveVideoTitle {
      font-size: 0.8rem;
    }
    .social-proof {
        font-size: 8.5vw;
    }

    .cellphone-surround-responsive-text-leftside {
      text-align: center;
    }

    .cellphone-surround-responsive-text-rightside {
      text-align: center;
    }

    .fixedSizeResponsiveVideoImage {
      width: 85vw;
      height: auto;
    }

    .tkc-scrollerThumbnail {
      margin-right: 12px;
    }

    .responsiveCategoryTitle {
      font-size: 16px;
    }

    .starbat {
      height: 3vh;
    }

    .expandButton {
      width: 64px;
    }

    .playButtonOverlay {
      position: absolute;
      z-index: 999;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 30%;
      width: 100%;
    }

    .playButton {
      width: 30%;
      max-width: 64px;
    }

    .fixedSizeCourseVideoImage {
      height: 10vh;
      border: 1px solid #CCCCCC;
    }
  }
